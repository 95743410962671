import { useEffect, useState } from "react";
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

const Consejos = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const wordpressSites = [
        'https://www.marthadebayle.com/wp-json/wp/v2/posts?per_page=4&tags=52323',
    ];
    useEffect(() => {
        const fetchPosts = async () => {
            try {
                // Realiza solicitudes a cada sitio, siguiendo redirecciones
                const promises = wordpressSites.map(async url => {
                    const response = await fetch(url, { redirect: 'follow' });
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.error(`Failed to fetch from ${url}:`, response.status);
                        return [];
                    }
                });

                const results = await Promise.all(promises);

                // Combina los resultados de todos los sitios
                const combinedPosts = results.flat();

                setPosts(combinedPosts);
            } catch (error) {
                console.error('Error fetching posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);
    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div id="consejos" className="fondoComponents mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-3 mt-3">
                            <div className="container">
                                <h1 className="ConsejosTitulo">CONSEJOS PARA EMPRENDEDORES</h1>
                            </div>
                        </div>
                        {!loading &&
                            posts.length > 0 &&
                            <swiper-container navigation="true" slides-per-view={window.screen.width < 1024 ? "1" : "2"} >
                                {posts.map(post => (
                                    <swiper-slide className="container mb-5" key={post.id} >
                                        <div className="card mx-auto contenedorPost">
                                            <div className="image-containerPost text-center">
                                                <img src={post.yoast_head_json.og_image[0].url} class="card-img-top mt-3" />
                                            </div>
                                            <div class="card-body text-center pb-5">
                                                <h2 className="mt-3 text-center postText">{post.title.rendered}</h2>
                                                <p className="mt-3 fs-7" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                                <a className="btn btn-primary text-center" href={post.link} target="_blank" rel="noopener noreferrer">
                                                    Leer más
                                                </a>
                                            </div>
                                        </div>

                                    </swiper-slide>
                                ))}
                            </swiper-container>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Consejos;