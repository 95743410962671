const ThanksPage = () => {
    return (
        <>
            <script>
                fbq('track', 'CompleteRegistration');
            </script>

            <div id="thanksPage">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 text-center">
                            <img className="thanks" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/thanks.jpg" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}
export default ThanksPage;