const Premios = () => {
    return (
        <>
            <div id="premios" className="">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mt-5">
                            <h1 className="tituloPremios">Premios</h1>
                        </div>
                        <div className="margenText col-12 col-md-7 mt-5 order-2 order-md-1">
                            <p className="premiosText">HASTA</p>
                            <p className="premiosTextAzul">500</p>
                            <p className="premiosText">MIL PESOS</p>
                            <p className="premiosText">EN EFECTIVO</p>
                        </div>
                        <div className="margenImg col-12 col-md-5 mt-5 order-1 order-md-2">
                            <img src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/money_bag_2-2.webp" className="imgBag" />
                        </div>
                        <div className="col-12 order-3 order-md-3 mt-4 contenedorFrase mx-auto">
                            <h1 className="fraseText">PARTICIPAR TE PUEDE CAMBIAR EL FUTURO</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Premios;