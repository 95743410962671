import './App.css';
import AppRouter from './routes/AppRouter';

function App() {
  return (
    <div className="App">
     <AppRouter/>
    </div>
  );
}

export default App;
