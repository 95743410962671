import { HashLink } from "react-router-hash-link";
const Header = () => {
    const handleNavLinkClick = () => {
        const navbarCollapse = document.getElementById('navbarSupportedContent');
        if (navbarCollapse.classList.contains('show')) {
          const bsCollapse = new window.bootstrap.Collapse(navbarCollapse);
          bsCollapse.hide();
        }
      };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top ">
                <div className="container-fluid">
                    {/*window.screen.width < 1024 ? <div></div> : <img src={process.env.PUBLIC_URL + "/image/logos/logoHeader.png"} width="100px" alt='logoEECH' />*/}
                    <button className="mx-auto navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="ml-5 collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="text-center bordeMenu navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <HashLink className="nav-link" to={"/#home"} spy={true} smooth={true} duration={500} onClick={handleNavLinkClick}>Home</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className="nav-link" to={"/#premios"} spy={true} smooth={true} duration={500} onClick={handleNavLinkClick}>Premios</HashLink>
                            </li>
                            <li className="nav-item">
                                <HashLink className='nav-link' to={'/#casos' } onClick={handleNavLinkClick}>Casos de Éxito</HashLink>
                            </li>
                            {/*<li className="nav-item">
                                <HashLink className='nav-link' to={'/#ediciones'}>Ediciones</HashLink>
                            </li>*/}
                            <li className="nav-item">
                                <a className="nav-link" href="/avisoDePrivacidad">Aviso de Privacidad</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/bases">Bases y Mecánica</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </>
    )
}
export default Header;