import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <div className="fondoFotter bg-black">
            <div className="container">
                <div className="row">
                    <div className="col text-center">
                        <Link to="" target="_blank"> <img className="iconFooterMercado mb-3 mt-3" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/MP_RGB+-+HANDSHAKE-04.png" /></Link>
                    </div>
                </div>
                <div className="row text-center pt-2 containerFooter">
                    <div className="col-4 text-end">
                        <Link to="https://www.mmkgroup.com.mx/" target="_blank"> <img className="iconFooterMMK mb-3 mt-3" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/LOGO+MMK+FOOTER.png" /></Link>

                    </div>
                    <div className="col-4 containerFooter">
                        <Link to="https://www.marthadebayle.com/" target="_blank"><img className="iconFooter mb-3 mt-3" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/LOGO+MD+FOOTER.png" /></Link>

                    </div>
                    <div className="col-4 text-start containerFooter">
                        <Link to="https://wradio.com.mx" target="_blank"><img className="iconFooterW mb-3 mt-3" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/LOGO+W+RADIO+FOOTER.png" /></Link>
                    </div>
                    <div className="col-12 mt-4">
                    <h5 className="text-center text-light mt-4 pb-3">DGRTC/5087/2024</h5>
                        <h5 className="titulosECCH">#ENCHULAMEELCHANGARRO</h5>
                        <p className="titulosECCH">ALEJANDRO DUMAS 241 / COL. POLANCO REFORMA / CP 11550 / MÉXICO CDMX / TELÉFONO 9126 2222</p>
                    </div>
                    <div className="col-sm-12 text-center mt-2 mb-3">
                        <p className="letrasFooter">© Todos los Derechos Reservados de Media Marketing Knowledge Group www.mmkgroup.com.mx Prohibida la reproducción total o parcial, incluyendo cualquier medio electrónico o magnético. El valor total del premio es de 420 mil pesos en efectivo y libre de impuestos al primer lugar.</p>
                    </div>
                    {/*<div className="col-12 mb-4">
                        <Link className="letrasFooter" to="https://www.marthadebayle.com/v3/sin-categoria/politica-de-privacidad/">Politica de Privacidad</Link>
                    </div>*/}
                </div>
            </div>
        </div>
    )
}
export default Footer;