
import { Route, Routes, useLocation } from 'react-router-dom';
import Main from "../Components/Main";
import Terminos from "../Components/Terminos";
import Aviso from "../Components/Aviso";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import ThanksPage from '../Components/ThanksPage';
export const AppRouter = () => {
    const location = useLocation();
    return (
        <>
            <Header />
            <Routes location={location} key={location.pathname}>
                <Route path="/*" element={<Main/>} />
                <Route path="/avisoDePrivacidad" element={<Aviso />} />
                <Route path="/bases" element={<Terminos />} />
                <Route path='/registrocompleto' element={<ThanksPage/>}/>
            </Routes>
            <Footer />
        </>
    )
}
export default AppRouter;