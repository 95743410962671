// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();
const Casos = () => {
    return (
        <>
            <div id="casos" className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <h1 className="tituloCasos">NUESTROS CASOS DE ÉXITO</h1>
                        </div>
                        <swiper-container loop="true" class="mb-5" navigation="true" slides-per-view={window.screen.width < 1024 ? "1" : "2"}>
                            <swiper-slide>
                                <div class="card mb-3 mx-auto contenedorCardCasos" >
                                    <div class="row g-0">
                                        <div class="col-md-6">
                                           <a href='https://www.frutevia.com/' target='_blank'> <img className="imgGanadores" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/FRUTEVIA-GANADOR-2023-low2.webp" alt="..." /></a>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="card-body">
                                                <h5 class="card-title juradoText">FRUTEVIA</h5>
                                                <p class="card-text">Alonso Martínez, ganó la edición pasada con Frutevia, la gelatina endulzada con Stevia, es una de las opciones más sanas en el mercado. Con el premio impulsó sus redes sociales y ha conseguido estar en las grandes cadenas departamentales.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="card mb-3 mx-auto contenedorCardCasos" >
                                    <div class="row g-0">
                                        <div class="col-md-6">
                                            <a href='https://xilinat.com/' target='_blank'><img className="imgGanadores" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/XILINAT-LOW.jpg" alt="..." /></a>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="card-body">
                                                <h5 class="card-title juradoText">XILINAT</h5>
                                                <p class="card-text">Javier Larragoiti, llegó con un producto innovador que revoluciona la industria de los endulzantes. Gracias a “Enchúlame el Changarro”, ahora tiene más impacto en medios digitales e impresos.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                            <swiper-slide>
                                <div class="card mb-3 mx-auto contenedorCardCasos" >
                                    <div class="row g-0">
                                        <div class="col-md-6">
                                            <a href='https://www.agua-solida.com/' target='_blank'><img className="imgGanadores" src="https://eenchulame.s3.us-west-2.amazonaws.com/enchulameelchangarro2024/images/AQUA-SAFE-PRO-LOW.jpg" alt="..." /></a>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="card-body">
                                                <h5 class="card-title juradoText">Aqua Safe Pro</h5>
                                                <p class="card-text">Amilkar Mendizabal y su papá lograron llegar a la final de “Enchúlame el Changarro” gracias a su “agua sólida” un producto que literalmente salva a los jardines y cosechas de la sequía.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Casos;