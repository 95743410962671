const Aviso = () => {
    return (
        <>
            <div className="pb-5 fondoComponents">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-12 mt-5 bg-light">
                            <h1 className="tituloCasos text-center mt-5 mb-5">AVISO DE PRIVACIDAD</h1>
                            <div className="contenedorAviso p-3">
                                <p>BBMUNDO DIRECT, S.A. DE C.V. (el “Responsable”), con domicilio en Alejandro
                                    Dumas No. 241, Polanco-Reforma, Miguel Hidalgo, 11550, México, Distrito
                                    Federal, en cumplimiento con la Ley Federal de Protección de Datos Personales
                                    en Posesión de los Particulares, su respectivo Reglamento y demás disposiciones
                                    aplicables (en lo sucesivo la “Ley”), le informa que es responsable de recabar sus
                                    datos personales, del uso que se le dé a los mismos y de su protección, por lo que
                                    pone a su disposición el presente Aviso de Privacidad:</p>
                                <p className="fw-bold">Información a Recabar:</p>
                                <p>Los datos que usted como titular nos proporcione, serán tratados bajo los
                                    principios establecidos en la Ley (licitud, consentimiento, información, calidad,
                                    finalidad, lealtad, proporcionalidad y responsabilidad).</p>
                                <p>Hacemos de su conocimiento que, a fin de registrarse en el presente sitio web, se
                                    le pedirán los siguientes datos:</p>
                                <ul>
                                    <li>Datos de identificación y de contacto: Nombre completo, correo electrónico,
                                        teléfono móvil y celular, así como su fecha de nacimiento.</li>
                                </ul>
                                <p className="fw-bold">Finalidades del tratamiento de sus datos personales:</p >
                                <p>Los datos personales que recabamos en el sitio web, los utilizaremos para las
                                    siguientes finalidades:</p>
                                <ul>
                                    <li>Para identificar, ubicar, comunicar y contactar al usuario así
                                        como para enviarle información y/o mercancía.</li>
                                    <li>Para uso estadístico y científico; y la transmisión a terceros y socios
                                        comerciales por cualquier medio que permita la Ley.</li>
                                    <li>Para uso estadístico y científico; y
                                        la transmisión a terceros y socios comerciales por cualquier medio que permita la
                                        Ley.</li>
                                    <li>Para desarrollar, por sí o a través de sus afiliadas o cualquier tercero, estudios
                                        sobre los intereses, comportamientos y demografía de los titulares, a efecto de
                                        comprender mejor sus necesidades e intereses, y ofrecer mejores servicios o
                                        proveerles información relevante.</li>
                                    <li>Para mejorar nuestras iniciativas y estrategias comerciales y las de terceros con
                                        los que tenemos relación comercial y/o jurídica. Para analizar las páginas de
                                        internet visitadas, las búsquedas efectuadas por los titulares, así como para
                                        mejorar nuestra oferta de contenido y artículos, incluyendo personalización,
                                        presentación, programación y servicios.</li>
                                    <li>Para enviar información vía correo electrónico respecto de ofertas,
                                        descuentos, o cualquier otro tipo de información comercial.</li>
                                </ul>
                                <p>Para enviar información vía correo electrónico respecto de ofertas, descuentos, o
                                    cualquier otro tipo de información comercial. Para todos aquellos datos que se
                                    recaban por medios distintos a este sitio web, le solicitamos consultar el aviso de
                                    privacidad correspondiente, con el objeto de conocer las finalidades de dicha
                                    recopilación.</p>
                                <p className="fw-bold">Limitación del uso y divulgación de sus datos:</p>
                                <p>El Responsable tiene el compromiso de resguardar sus datos personales bajo
                                    estrictas medidas de seguridad físicas, técnicas y administrativas, las cuales han
                                    sido implementadas, conforme a la Ley, con el objeto de proteger dichos datos
                                    personales contra cualquier daño, pérdida, alteración, destrucción, uso, acceso o
                                    tratamiento no autorizados, y buscando, ante todo garantizar la confidencialidad
                                    de los datos y asegurarnos de minimizar cualquier riesgo o vulneración que
                                    pudiera presentarse.</p>
                                <p className="fw-bold">Ejercicio de los Derechos ARCO:</p>
                                <p>En todo momento, usted tiene derecho de acceder a los datos personales que
                                    poseamos y a los detalles del tratamiento de los mismos, así como a rectificarlos
                                    en caso de ser inexactos o solicitar su cancelación cuando considere que resulten
                                    ser excesivos o innecesarios para las finalidades que justificaron su obtención u
                                    oponerse al tratamiento de los mismos, mediante la solicitud respectiva en escrito
                                    libre que cumpla con los requisitos establecidos en la Ley. En caso de que
                                    requiera información sobre dicha solicitud, podrá hacerlo, enviando un correo
                                    electrónico a la dirección: privacidad@marthadebayle.com.</p>
                                <p>Una vez recibida su solicitud, le comunicaremos la determinación adoptada dentro
                                    del plazo que establece la Ley y en caso de que sea procedente, se hará efectiva
                                    de acuerdo a los plazos señalados por la misma.</p>
                                <p>Usted puede revocar el
                                    consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus
                                    datos personales. Para revocar su consentimiento deberá presentar una solicitud
                                    en los mismos términos que aquella para el ejercicio de sus Derechos ARCO.</p>
                                <p className="fw-bold">Uso de cookies y/o contadores de visitas:</p>
                                <p>Las “cookies” son pequeños archivos de texto que un servidor de web envía a su
                                    computadora, mismas que sirven para poder brindarle una mejor atención
                                    respecto de nuestros productos y/o servicios. Las cookies de una sesión
                                    recuerdan la actividad que usted llevó a cabo anteriormente en nuestro sitio web.
                                    Las cookies persistentes incluso hacen posible guardar sus preferencias en las
                                    diversas sesiones en que visite nuestro sitio web, lo cual nos permite personalizar,
                                    por ejemplo, la manera en que dicho sitio ponga a su disposición la información de
                                    su interés.</p>
                                <p>Así mismo, las cookies pueden utilizarse para evitar que le mostremos avisos,
                                    noticias y/o recomendaciones que pudieran interesarle, de conformidad con sus
                                    actividades anteriores en nuestro sitio web. Las cookies también se usan para
                                    implementar estrategias que nos permitan elevar la calidad de nuestros productos
                                    y/o servicios.</p>
                                <p>Los contadores de visita o “web beacons”, son por lo general imágenes gráficas
                                    que se ponen en un sitio web y se utilizan para contar el número de visitantes a un
                                    sitio web o incluso pueden permitir el acceso a algunas cookies.</p>
                                <p>El uso de web beacons en nuestro sitio web tiene la finalidad de reflejar
                                    estadísticas respecto de los productos y/o servicios que son de su interés. Estos
                                    contadores de visita normalmente no recopilan información diferente de la que su
                                    navegador nos proporciona como parte estándar de cualquier navegación en
                                    internet. Si usted eligiera la opción de desactivación de las cookies en su
                                    explorador de internet, el contador de visitas no podrá seguir rastreando
                                    específicamente su actividad.</p>
                                <p>Algunos sitios web o aplicaciones de nuestra página web podrán utilizar objetos
                                    almacenados localmente, como son las cookies de Flash o almacenamiento local
                                    con HTML5.</p>
                                <p>
                                    Este tipo de objetos almacenados localmente se utilizan para fines similares a los
                                    de las cookies pero generalmente pueden contener mayor cantidad de datos
                                    diferentes a los de las cookies del navegador. Usted puede modificar la
                                    configuración de su reproductor Flash mediante el administrador de configuración
                                    de conformidad con su preferencia sobre el almacenamiento de objetos locales
                                    compartidos, inclusive desactivar objetos locales compartidos solamente para
                                    determinados sitios web o desactivar totalmente el almacenamiento de objetos
                                    locales compartidos para todos los sitios web.
                                </p>
                                <p>
                                    La mayoría de los navegadores o exploradores de internet le permiten desactivar o
                                    permitir el uso de cookies. También puede eliminar las cookies de su computadora
                                    si su navegador se lo permite. Si desactiva las cookies, es posible que usted no
                                    pueda usar determinadas partes de nuestro sitio web y que sea necesario
                                    reinstalar una cookie de rechazo. En todo caso, usted contará con diversas
                                    opciones para limitar el acceso de las cookies y web beacons de nuestro sitio web
                                    en su computadora.
                                </p>
                                <p>
                                    Sobre la transferencia de sus datos personales: Le informamos que los datos
                                    recabados a través del sitio web no serán transferidos a terceros distintos o ajenos
                                    al Responsable. Sin embargo, para aquellos datos recabados por cualquier otro
                                    medio, le solicitamos revisar los avisos de privacidad correspondientes.
                                </p>
                                <p className="fw-bold">Cambios al Aviso de Privacidad:</p>
                                <p>El presente Aviso de Privacidad podrá ser modificado en el futuro. En todo caso,
                                    cualquier modificación al mismo se hará de su conocimiento mediante la
                                    publicación del mismo en la siguiente página web:
                                    http://www.marthadebayle.com/sitio/md/sin-categoria/politica-de-privacidad/</p>
                                <p>*Si
                                    usted no manifiesta su oposición, se entiende que ha leído y acepta los términos
                                    contenidos en el presente Aviso de Privacidad.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Aviso;