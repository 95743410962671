import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import * as ApiRest from '../Functions/utils'
import { showToast } from '../Functions/notifications'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReactCardFlip from 'react-card-flip';
import { useNavigate } from 'react-router-dom';

const Formulario = () => {
    const formikRef = useRef();
    const formikRefNewCuenta = useRef();
    const [user, setUser] = useState();
    const [isValid, setIsValid] = useState(true);
    const [isFlipped, setIsFlipped] = useState(false);
    const [sharedValue, setSharedValue] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    //Valores para obtener un nuevo id de cuentahabiente//
    const initialValuesNewCuenta = {
        nombreNewCuenta: '',
        apellidoNewCuenta: '',
        correoElectronico: ''
    }
    const validationSchemaNewCuenta = Yup.object().shape({
        nombreNewCuenta: Yup.string().required('Ingresa el campo nombre'),
        apellidoNewCuenta: Yup.string().required('Ingresa el campo apellidos'),
        correoElectronico: Yup.string().email("Formato de correo electrónico invalido").required("Ingresa el campo de correo electrónico")
    })
    const initialValues = {
        idCuenta: sharedValue || '',
        nameEmpresa: '',
        history: '',
        option: '',
        nombrePM: '',
        razonSocial: '',
        rfc: '',
        giro: '',
        monto: '',
        calle: '',
        numExt: '',
        numInterior: '',
        colonia: '',
        delegacion: '',
        cp: '',
        ciudad: '',
        state: '',
        numeroTel1: '',
        numeroTel2: '',
        acceptTerms: false,
    }
    const validationSchema = Yup.object().shape({
        idCuenta: Yup.number().typeError('Debe ser un número').required('Valida tu Id de CuentaHabiente'),
        nameEmpresa: Yup.string().required('Ingresa el nombre de la empresa'),
        history: Yup.string()
            .required('Campo requerido')
            .min(10, 'Debe tener al menos 10 caracteres')
            .max(200, 'No puede exceder los 200 caracteres'),
        option: Yup.string().required('Selecciona el tipo del persona que eres'),
        nombrePM: Yup.string().when('option', { is: (value) => value === 'moral', then: () => Yup.string().required('Agrega nombre del representante legal') }),
        razonSocial: Yup.string().required('Agrega la razón social'),
        rfc: Yup.string().matches(/^([a-zñ&A-ZÑ&]{3,4}){1}(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01]))?(?:- ?)?([a-zA-Z\d]{2})([aA\d])$/, 'RFC inválido').required('El RFC es requerido'),
        giro: Yup.string().required('Ingresa el giro de la empresa'),
        monto: Yup.number().typeError('Debe ser un número').required('Agrega el monto anual de facturación'),
        calle: Yup.string().required('Agrega tu calle'),
        numExt: Yup.number().typeError('Debe ser un número').required('Agrega tu número exterior'),
        colonia: Yup.string().required('Agrega la colonia'),
        delegacion: Yup.string().required('Agrega la delegación'),
        cp: Yup.number().typeError('Debe ser un número').required('Agrega tu código postal'),
        ciudad: Yup.string().required('Agrega tu ciudad'),
        state: Yup.string().required('Opción no válida').oneOf(ApiRest.twowns),
        numeroTel1: Yup.string()
            .matches(/^\d{10}$/, 'Número telefónico no válido. Debe tener 10 dígitos')
            .required('Campo requerido'),
        numeroTel2: Yup.string()
            .matches(/^\d{10}$/, 'Número telefónico no válido. Debe tener 10 dígitos')
            .required('Campo requerido'),
        acceptTerms: Yup.bool()
            .oneOf([true], 'Debe aceptar los términos y condiciones')
            .required('Debe aceptar los términos y condiciones')
    })
    const resetForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }

    }
    const resetForm2 = () => {
        if (formikRefNewCuenta.current) {
            formikRefNewCuenta.current.resetForm();
        }
    }
    const ValidarID = async (id) => {
        console.log("IdCuenta es ", id);

        if (id) {
            const valid = await ApiRest.ValidateCuenta(id);
            if (valid) {
                setUser(valid)
                setIsValid(false);
                window.document.getElementById('idCuenta').classList = "form-control  border border-3 border-success";
                showToast('ID CuentaHabiente Validado', "success")
            } else if (valid == false) {
                setIsValid(true);
                window.document.getElementById('idCuenta').classList = "form-control border border-3 border-danger";
                showToast('ID CuentaHabiente no encontrado', "error")
            }
        } else {
            console.log("ID CuentaHabiente esta vacio ");
            window.document.getElementById('idCuenta').classList = "form-control border border-3 border-danger";
            showToast('Campo Vacio', "error")
        }
    }
    const handleSubmit = async (values) => {
        setLoader(true);
        //asignando nuevos valores al objeto.
        const booltypePerson = ApiRest.ValidateInputTypePerson(values);
        values.user_id = user;
        values.booltypePerson = booltypePerson;
        //Enviando datos al servidor
        const respuesta = await ApiRest.RegistrarEmpresa(values)
        if (respuesta.data.status) {
            showToast(respuesta.data.message, "success")
            setIsValid(true);
            setUser();
            resetForm();
            setSharedValue('')
            setLoader(false);
            window.document.getElementById('idCuenta').classList = "form-control"
            navigate('/registrocompleto');
            window.location.reload();
        } else {
            showToast(respuesta.data.message, "error")
            setIsValid(true);
            setUser();
            resetForm();
            setSharedValue('')
            setLoader(false);
            window.document.getElementById('idCuenta').classList = "form-control";
        }
    }
    const handleSubmitCuenta = async (valuesCuenta) => {
        const respuesta = await ApiRest.OauthToken();
        const sendData = await ApiRest.RegistrarCuentaHabiente(respuesta, valuesCuenta);
        console.log(sendData.data.data.account_num);
        setSharedValue(sendData.data.data.account_num);
        ValidarID(sendData.data.data.account_num)
        setIsFlipped((prev) => !prev)
        resetForm2();
        
    }

    return (
        <>
            <ToastContainer limit={1} />
            <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
                    {({ values, errors, touched, resetForm, setFieldValue }) => (
                        <Form >
                            <div className='container'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <label className="text-start">ID CuentaHabiente:</label>
                                        <div className="input-group input-group-sm ">
                                            <span className="input-group-text" id="inputGroup-sizing-sm">MD-</span>
                                            <Field id="idCuenta" type="number" name="idCuenta" className="form-control" disabled={!isValid} onBlur={() => ValidarID(values.idCuenta)}
                                                style={{
                                                    borderColor: errors.idCuenta && touched.idCuenta ? 'red' : '',
                                                    borderWidth: errors.idCuenta && touched.idCuenta ? '2px' : '',
                                                }}
                                            />
                                        </div>
                                        <ErrorMessage name="idCuenta" component="span" style={{ color: 'red' }} className='text' />
                                        <p className="form-text text-start fw-bold"><a target="_blank" onClick={() => setIsFlipped((prev) => !prev)} className='text-decoration-none text-dark sacarID'>SACA AQUÍ TU ID</a></p>
                                        <div className='col-12 text-center'>
                                            <h4>Datos empresariales</h4>
                                            <div className="row ">
                                                <div className="col-12 col-sm-12 mb-3">
                                                    <label className="">Nombre de la Empresa</label>
                                                    <Field type="text" name="nameEmpresa" className="form-control" style={{
                                                        borderColor: errors.nameEmpresa && touched.nameEmpresa ? 'red' : '',
                                                        borderWidth: errors.nameEmpresa && touched.nameEmpresa ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="nameEmpresa" component="span" style={{ color: 'red' }} className='mt-3' />
                                                </div>
                                                <div className='col-12 col-sm-12 mb-3'>
                                                    <label>Cuéntanos de tu Changarro</label>
                                                    <Field as="textarea" name="history" className="form-control" style={{
                                                        borderColor: errors.history && touched.history ? 'red' : '',
                                                        borderWidth: errors.history && touched.history ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="history" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-12 col-sm-12 mb-4">
                                                    <label className="">Selecciona el tipo de persona:</label>
                                                </div>
                                                <div className="col-6 col-sm-6">
                                                    <div className="form-check d-inline-block">
                                                        <label style={{
                                                            color: errors.option && touched.option ? 'red' : '',
                                                            borderWidth: errors.option && touched.option ? '2px' : '',
                                                        }} >
                                                            <Field type="radio" name="option" value="fisica" />
                                                            Persona Física con Actividad Empresarial
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 ">
                                                    <div className="form-check d-inline-block">
                                                        <label style={{
                                                            color: errors.option && touched.option ? 'red' : '',
                                                            borderWidth: errors.option && touched.option ? '2px' : '',
                                                        }} >
                                                            <Field type="radio" name="option" value="moral" />
                                                            Persona Moral
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="option" component="div" style={{ color: 'red' }} />
                                                {values.option === 'moral' && (
                                                    <div className="col-12 mt-3">
                                                        <label>Nombre del representante legal o principal accionista :</label>
                                                        <Field type="text" name="nombrePM" className="form-control" style={{
                                                            borderColor: errors.nombrePM && touched.nombrePM ? 'red' : '',
                                                            borderWidth: errors.nombrePM && touched.nombrePM ? '2px' : '',
                                                        }} />
                                                        <ErrorMessage name="nombrePM" component="span" style={{ color: 'red' }} />
                                                    </div>
                                                )}
                                                <div className="d-block  col-12 mb-3 mt-3">
                                                    <label className="form-label ">Razón social:</label>
                                                    <Field type="text" className="form-control" id="razonSocial" name="razonSocial" style={{
                                                        borderColor: errors.razonSocial && touched.razonSocial ? 'red' : '',
                                                        borderWidth: errors.razonSocial && touched.razonSocial ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="razonSocial" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6 mb-4">
                                                    <label className="">RFC:</label>
                                                    <Field type="text" className="form-control" name="rfc" style={{
                                                        borderColor: errors.rfc && touched.rfc ? 'red' : '',
                                                        borderWidth: errors.rfc && touched.rfc ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="rfc" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6">
                                                    <label>Giro:</label>
                                                    <Field type="text" className="form-control" name="giro" style={{
                                                        borderColor: errors.giro && touched.giro ? 'red' : '',
                                                        borderWidth: errors.giro && touched.giro ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="giro" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="d-block  col-12 mb-3 mt-3">
                                                    <label className="form-label ">Monto Anual de Facturación :</label>
                                                    <Field type="number" className="form-control" id="monto" name="monto" style={{
                                                        borderColor: errors.monto && touched.monto ? 'red' : '',
                                                        borderWidth: errors.monto && touched.monto ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="monto" component="span" style={{ color: 'red' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <h4>Datos personales</h4>
                                            <div className='row mt-3'>
                                                <div className="col-12 col-sm-6 mb-3">
                                                    <label className="form-label">Calle:</label>
                                                    <Field type="text" className="form-control" name="calle" style={{
                                                        borderColor: errors.calle && touched.calle ? 'red' : '',
                                                        borderWidth: errors.calle && touched.calle ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="calle" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6 col-sm-6 mb-3">
                                                    <label className="form-label">N° Exterior:</label>
                                                    <Field type="text" className="form-control" name="numExt" style={{
                                                        borderColor: errors.numExt && touched.numExt ? 'red' : '',
                                                        borderWidth: errors.numExt && touched.numExt ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="numExt" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6 col-sm-6 mb-3">
                                                    <label className="form-label">N° Interior:</label>
                                                    <Field type="text" className="form-control" name="numInterior" style={{
                                                        borderColor: errors.numInterior && touched.numInterior ? 'red' : '',
                                                        borderWidth: errors.numInterior && touched.numInterior ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="numInterior" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6 col-sm-6 mb-3">
                                                    <label className="form-label">Colonia:</label>
                                                    <Field type="text" className="form-control" name="colonia" style={{
                                                        borderColor: errors.colonia && touched.colonia ? 'red' : '',
                                                        borderWidth: errors.colonia && touched.colonia ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="colonia" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6 col-sm-6 mb-3">
                                                    <label className="form-label">Delegación o Municipio:</label>
                                                    <Field type="text" className="form-control" name="delegacion" style={{
                                                        borderColor: errors.delegacion && touched.delegacion ? 'red' : '',
                                                        borderWidth: errors.delegacion && touched.delegacion ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="delegacion" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6 col-sm-6 mb-3">
                                                    <label className="form-label">Código Postal:</label>
                                                    <Field type="text" className="form-control" name="cp" style={{
                                                        borderColor: errors.cp && touched.cp ? 'red' : '',
                                                        borderWidth: errors.cp && touched.cp ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="cp" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-6 col-sm-6 mb-3">
                                                    <label className="form-label">Ciudad:</label>
                                                    <Field type="text" className="form-control" name="ciudad" style={{
                                                        borderColor: errors.ciudad && touched.ciudad ? 'red' : '',
                                                        borderWidth: errors.ciudad && touched.ciudad ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="ciudad" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-12 col-sm-6 mb-3">
                                                    <label className="form-label">Estado:</label>
                                                    <Field name="state" as="select" className="form-control" style={{
                                                        borderColor: errors.state && touched.state ? 'red' : '',
                                                        borderWidth: errors.state && touched.state ? '2px' : '',
                                                    }}>
                                                        <option value="">Seleccionar..</option>
                                                        {ApiRest.twowns.map((state, index) => (
                                                            <option key={index} value={state}>{state}</option>
                                                        ))}
                                                    </Field>
                                                    <ErrorMessage name="state" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-12 col-sm-12 mb-3">
                                                    <label className="form-label">Número telefonico 1:</label>
                                                    <Field type="text" className="form-control" name="numeroTel1" style={{
                                                        borderColor: errors.numeroTel1 && touched.numeroTel1 ? 'red' : '',
                                                        borderWidth: errors.numeroTel1 && touched.numeroTel1 ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="numeroTel1" component="span" style={{ color: 'red' }} />
                                                </div>
                                                <div className="col-12 col-sm-12 mb-3">
                                                    <label className="form-label">Número telefonico 2:</label>
                                                    <Field type="text" className="form-control" name="numeroTel2" style={{
                                                        borderColor: errors.numeroTel2 && touched.numeroTel2 ? 'red' : '',
                                                        borderWidth: errors.numeroTel2 && touched.numeroTel2 ? '2px' : '',
                                                    }} />
                                                    <ErrorMessage name="numeroTel2" component="span" style={{ color: 'red' }} />
                                                </div>
                                                {/*<div>
                                            <br/>
                                           <div className='col-12 mt-1 mb-5'>
                                                <Field type="checkbox" name="acceptTermsColab" className="form-check-input" style={{
                                                    borderColor: errors.acceptTermsColab && touched.acceptTermsColab ? 'red' : '',
                                                    borderWidth: errors.acceptTermsColab && touched.acceptTermsColab ? '2px' : '',
                                                }} />
                                                <label className="form-check-label" > Al aceptar participar en el concurso darán consentimiento para que compartan los datos personales a los patrocinadores y puedan contactarlos por cualquier medio (teléfono, mensaje, correo electrónico, etc.) para hacer promoción y publicidad de sus productos, así como tener un ID de cuentahabiente si no cuentan con uno.</label>
                                                <br/>
                                                <ErrorMessage name="acceptTermsColab" component="span" style={{ color: 'red' }} />
                                                </div>
                                        </div>*/}
                                                <div>
                                                    <br />
                                                    <div className='col-12 mt-1 mb-5'>
                                                        <Field type="checkbox" name="acceptTerms" className="form-check-input" style={{
                                                            borderColor: errors.acceptTerms && touched.acceptTerms ? 'red' : '',
                                                            borderWidth: errors.acceptTerms && touched.acceptTerms ? '2px' : '',
                                                        }} />
                                                        <label className="form-check-label" >   Acepto los términos y condiciones</label>
                                                        <br />
                                                        <ErrorMessage name="acceptTerms" component="span" style={{ color: 'red' }} />
                                                    </div>
                                                </div>
                                                {
                                                    loader
                                                        ?
                                                        <div className='text-center'>
                                                            <div class="spinner-border text-primary" role="status">
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                        :
                                                        <button type="submit" className="btn btn-primary btn-lg">
                                                            Enviar
                                                        </button>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Form>
                    )}
                </Formik>

                <Formik innerRef={formikRefNewCuenta} initialValues={initialValuesNewCuenta} validationSchema={validationSchemaNewCuenta} onSubmit={handleSubmitCuenta}>
                    {({ errors, touched }) => (
                        <Form>
                            <div className="CardBack">
                                <a className="btn btn-primary" onClick={() => setIsFlipped((prev) => !prev)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                    </svg>
                                </a>
                                <h2 className='text-center mb-5 '>Obtener  ID de CuentaHabiente</h2>
                                <div className='row'>
                                    <div className='col col-12 b-3'>
                                        <label className="">Nombre</label>
                                        <Field type="text" name="nombreNewCuenta" className="form-control" style={{
                                            borderColor: errors.nombreNewCuenta && touched.nombreNewCuenta ? 'red' : '',
                                            borderWidth: errors.nombreNewCuenta && touched.nombreNewCuenta ? '2px' : '',
                                        }} />
                                        <ErrorMessage name="nombreNewCuenta" component="span" style={{ color: 'red' }} className='mt-3' />
                                    </div>
                                    <div className='col col-12 mb-3'>
                                        <label className="">Apellidos</label>
                                        <Field type="text" name="apellidoNewCuenta" className="form-control" style={{
                                            borderColor: errors.apellidoNewCuenta && touched.apellidoNewCuenta ? 'red' : '',
                                            borderWidth: errors.apellidoNewCuenta && touched.apellidoNewCuenta ? '2px' : '',
                                        }} />
                                        <ErrorMessage name="apellidoNewCuenta" component="span" style={{ color: 'red' }} className='mt-3' />
                                    </div>
                                    <div className='col col-12 mb-3'>
                                        <label className="">Correo Electrónico</label>
                                        <Field type="text" name="correoElectronico" className="form-control" style={{
                                            borderColor: errors.correoElectronico && touched.correoElectronico ? 'red' : '',
                                            borderWidth: errors.correoElectronico && touched.correoElectronico ? '2px' : '',
                                        }} />
                                        <ErrorMessage name="correoElectronico" component="span" style={{ color: 'red' }} className='mt-3' />
                                    </div>
                                    <div className='col-12 text-center'>
                                        <button type="submit" className="btn btn-primary btn-lg">
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ReactCardFlip>
        </>
    )
}
export default Formulario;