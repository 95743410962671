import axios from 'axios';
//Arreglo de los estados
export const twowns = [
    "Aguascalientes",
    "Baja California",
    "Baja California Sur",
    "Campeche",
    "Chiapas",
    "Chihuahua",
    "Coahuila",
    "Colima",
    "CDMX",
    "Durango",
    "Guanajuato",
    "Guerrero",
    "Hidalgo",
    "Jalisco",
    "México",
    "Michoacan",
    "Morelos",
    "Nayarit",
    "Nuevo Leon",
    "Oaxaca",
    "Puebla",
    "Querétaro",
    "Quintana Roo",
    "San Luis Potosi",
    "Sinaloa",
    "Sonora",
    "Tabasco",
    "Tamaulipas",
    "Tlaxcala",
    "Veracruz",
    "Yucatan",
    "Zacatecas",
];
//Funciones API REST
/* Obtener token de MMKGroup */
export const OauthToken = async () => {
    const response = await axios({
        url: process.env.REACT_APP_API + "/oauth/token",
        method: "POST",
        data: {
            grant_type: "client_credentials",
            client_id: process.env.REACT_APP_CLIENT,
            client_secret: process.env.REACT_APP_SECRET
        }
    }).then((response) => {
        return response.data.access_token
    }).catch((e) => {
        return "Error al obtener el token de autenticación"
    });
    return response;
}
/* Validar si existe un cuentaHabiente */
export const ValidateCuenta = async (id) => {
    const response = await axios({
        url: process.env.REACT_APP_API + "/api/users/account_num/" + id,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => {
            return response.data.data.id;
        })
        .catch((e) => {
            return false
        });
    return response
}
export const ValidateInputTypePerson = (values) => {
    if (values.option == "moral") {
        return true
    } else {
        return false;
    }
}
export const RegistrarEmpresa = async (dataEmpresa) => {
    let dataSend = {
        microsite_id: 114,
        user_id: dataEmpresa.user_id,
        telephone: dataEmpresa.numeroTel1,
        cellphone: dataEmpresa.numeroTel2,
        commercial_name: dataEmpresa.nameEmpresa,
        company_name: dataEmpresa.razonSocial,
        is_pf: !dataEmpresa.booltypePerson,
        is_pm: dataEmpresa.booltypePerson,
        legal_representation: dataEmpresa.nombrePM,
        business_activity: dataEmpresa.giro,
        description: dataEmpresa.history,
        rfc: dataEmpresa.rfc,
        annual_growth: dataEmpresa.monto,
        address: {
            street: dataEmpresa.calle,
            city: dataEmpresa.ciudad,
            cp: dataEmpresa.cp,
            no_ext: dataEmpresa.numExt,
            no_int: dataEmpresa.numInterior,
            state: dataEmpresa.state,
            suburb: dataEmpresa.colonia,
            municipality: dataEmpresa.delegacion,
        }
    }
    const response = await axios({
        url: process.env.REACT_APP_API + '/api/eech',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data:dataSend,
    })
        .then((response) => {
            return response
        })
        .catch((e) => {
            return e
        })
    return response;
}
/*Registrar Cuentahabiente*/
export const RegistrarCuentaHabiente = async (token,dataCuentaHabiente)=>{
    let data = {
        "brand_id": 1,
        "name": dataCuentaHabiente.nombreNewCuenta,
        "lastname": dataCuentaHabiente.apellidoNewCuenta,
        "email": dataCuentaHabiente.correoElectronico,
    }
    const response = axios({
        url: process.env.REACT_APP_API + '/api/users',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + token
        },
        data,
    }).then((response) => {
        return response
    }).catch((e) => {
        return response
    })
    return response
}