import {  toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const showToast = (message,typeToast) =>{
    let varToast;
        switch (typeToast) {
            case "success":
                varToast = toast.success;
                break;
            case "warning":
                varToast = toast.warning;
                break;
            case "error":
                varToast = toast.error;
                break;
            default:
                varToast = toast.info;
        }
        varToast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
}