const Terminos = () => {
    return (
        <>
            <div className="pb-5 fondoComponents">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col mt-5 bg-light">
                            <h1 className="titulosTerminos text-center mt-5 mb-3">Bases</h1>
                            <div className="contenedorTerminos p-3">
                                <p>1. Podrán participar personas de nacionalidad mexicana, mayores de 18 años de edad
                                    (contar con identificación oficial vigente con fotografía), que habiten en la República
                                    Mexicana y que quieran enchular su negocio.</p>
                                <p>2. Deberán ser cuentahabientes del programa de radio &quot;Martha Debayle en W&quot;. Cualquier
                                    persona que quiera ser CUENTAHABIENTE puede registrarse de manera GRATUITA en
                                    la página de Debayle http://marthadebayle.com/, donde una vez que se registren
                                    obtendrán un número único que será su registro de CUENTAHABIENTE. No existe
                                    ninguna limitación para ser CUENTAHABIENTE sólo hay que registrarse.</p>
                                <p>3. Deberán tener un negocio, cuyo local o establecimiento se encuentre ubicado en la
                                    zona urbana de cualquier parte de la República Mexicana.</p>
                                <p>4.El negocio debe tener al menos un año de establecido y tener ingresos comprobables
                                    mínimos de $100,000.00 M.N (cien mil pesos 00/100) al mes.</p>
                                <p>5. Los gastos de transportación, hospedaje y alimentación de las personas participantes
                                    que acudirán al programa de radio “Martha Debayle en W”, a las asesorías, así como a la
                                    selección de ganador y entrega de premio correrán por cuenta y cargo de las personas
                                    participantes.</p>
                                <p>
                                    6. Al aceptar participar en el concurso darán consentimiento para que se compartan sus
                                    datos personales a los patrocinadores y puedan contactarlos por cualquier medio
                                    (teléfono, mensaje, correo electrónico, etc.) para hacer promoción y publicidad de sus
                                    productos.
                                </p>
                            </div>
                            <div className="contenedorTerminos p-3">
                                 <h5 className="text-center pb-3">Permiso SEGOB DGRTC/5087/2024</h5>
                                <h1 className="titulosTerminos text-center ">Mecánica</h1>
                                <p>1. Del 17 de septiembre al 18 de octubre de 2024 día en que se cerrará la convocatoria,
                                    las personas interesadas en participar deberán ingresar al micro sitio del Concurso
                                    www.enchulameelchangarro.com.mx, www.wradio.com.mx y www.marthadebayle.com
                                    registrarse mediante el llenado de un formulario relativo a los datos personales de quien
                                    participa (nombre, nacionalidad, edad, domicilio, teléfono y correo electrónico, así como
                                    cualquier otra información que se considere necesaria para conocer el perfil de la persona
                                    participante), las características del negocio (nombre del local o establecimiento, nombre
                                    del propietario del local o establecimiento, giro del negocio, así como cualquier otro dato
                                    necesario para conocer el perfil del negocio) y la razón por la cual quieren participar en el
                                    Concurso.</p>
                                <p>2. Del 28 al 30 de octubre el staff de Martha Debayle, la producción del programa de
                                    radio “Martha Debayle en W” revisará los registros realizados por las personas
                                    participantes, seleccionarán a 10 semifinalistas del Concurso, tomando en cuenta el
                                    crecimiento, las, proyecciones y el conocimiento de su negocio para participar en el
                                    Concurso, acorde a lo manifestado en su registro.</p>

                                <p>
                                    3. El 31 de octubre de 2024, se contactará vía telefónica a las 10 personas
                                    semifinalistas y se les notificará su participación en el concurso.
                                </p>
                                <p>
                                    4. El 8 de noviembre de 2024, en el programa de radio “Martha Debayle en W”, se
                                    llevará a cabo la presentación de las 10 personas semifinalistas, ante los jueces
                                    incluyendo a Martha Debayle, en la que las personas participantes deberán exponer de
                                    qué trata su negocio y la razón por la que quieren enchular su changarro. Sólo las 5
                                    personas con el mejor “elevator pitch”, pasarán a la siguiente ronda.
                                </p>
                                <p>
                                    5.Entre el 11 y 14 de noviembre 2024 las 5 personas semifinalistas tendrán asesorías
                                    con el jurado en el lugar y horario que les indique la producción del programa de radio
                                    “Martha Debayle en W” para que en la semifinal del Concurso tengan un mejor
                                    desempeño para exponer de qué trata su negocio e indiquen cómo invertirían el premio
                                    objeto del Concurso.
                                </p>
                                <p>
                                    6. El 15 de noviembre de 2024, en el programa de radio “Martha Debayle en W”, se
                                    llevará a cabo la semifinal en la que las 5 personas semifinalistas deberán exponer
                                    nuevamente de qué trata su negocio e indicar cómo invertirían el premio objeto del
                                    Concurso; el jurado seleccionará a 3 finalistas, tomando en cuenta el desempeño en su
                                    exposición de acuerdo a lo aprendido durante la semana de mentorías. Las 3 personas
                                    finalistas se darán a conocer ese mismo día en el programa de radio antes citado, lo
                                    anterior ante la presencia de un Supervisor o Supervisora de la Secretaría de
                                    Gobernación.
                                </p>
                                <p>
                                    7. Entre el 18 y el 21 de noviembre de 2024, las 3 personas finalistas nuevamente
                                    tendrán asesorías con el jurado en el lugar y horario que les indique la producción del
                                    programa de radio “Martha Debayle en W” para que en la gran final del Concurso
                                    tengan un mejor desempeño para exponer de qué trata su negocio e indiquen cómo
                                    invertirían el premio objeto del Concurso.
                                </p>
                                <p>
                                    8. El 22 de noviembre de 2024 en el programa de radio “Martha Debayle en W” y ante
                                    la presencia de un Supervisor o Supervisora de la Secretaría de Gobernación, se llevará
                                    a cabo la gran final en la que las 3 personas finalistas deberán exponer nuevamente de
                                    qué trata su negocio e indiquen cómo invertirían el premio objeto del Concurso, el
                                    jurado seleccionará a la persona ganadora del primer lugar que será aquella persona
                                    finalista que hayan tenido la habilidad de convencer al jurado de que su modelo de
                                    negocio es el mejor, con base al desempeño mostrado en su exposición.
                                </p>
                                <p>
                                    9. Durante la final del Concurso, los jueces harán una serie de preguntas que incluirán:
                                    <ul>
                                        <li>¿Cómo le sacarán provecho al premio?</li>
                                        <li>¿Cuáles son las ventajas que tienes contra la competencia?</li>
                                        <li>¿Qué pasaría si tu negocio estuviera prohibido? ¿Cómo le das la vuelta?</li>
                                        <li>¿Por qué la empresa rival no debería de ganar Enchúlame el Changarro 2024?</li>
                                    </ul>
                                </p>
                                <p></p>
                                <h2 className="text-center fw-bold text-uppercase mb-5 mt-5">Premio para el primer lugar</h2>
                                <p>Premio con valor de $500,000 M.N. (Quinientos mil pesos 00/100 Moneda
                                    Nacional) (Menos impuestos)*</p>
                                <p>1. Los premios se le entregarán a las personas ganadoras en la Ciudad de
                                    México, a más tardar el día 31 de marzo de 2024.</p>
                                <p>2. Para que las personas ganadoras puedan reclamar su premio es indispensable
                                    presentar identificación oficial vigente y comprobante de domicilio.</p>
                                <p>3. En caso de que alguno de los premios no sea reclamado por la persona
                                    ganadora, en un plazo de 90 (noventa) días siguientes a la selección de la
                                    persona ganadora, dicho premio podrá ser donado a la beneficencia pública o
                                    privada de la elección de la Radiodifusora o ser utilizado para futuras
                                    promociones.</p>
                                <p>
                                    Quienes respondan de mejor manera y hayan presentado un avance en cuanto al
                                    conocimiento de su negocio y sea avalado por los jueces, será el ganador del Concurso.
                                </p>
                                <p>
                                    10. Las personas semifinalistas y/o finalistas podrán acompañarse de otra persona que
                                    forme parte de su negocio, al momento de exponer de qué trata su negocio, pero
                                    únicamente a las personas finalistas, no a su acompañante, será a quienes se les
                                    entregue el premio, en caso de que resulten ganadoras.
                                </p>
                            </div>
                           <div className="container">
                           <h1 className="titulosTerminos text-center ">Premio</h1>
                            <p>Premio con valor total a $500,000.00 M .N. (Cuatrocientos Veinte Mil pesos 00/100
                                Moneda Nacional), consistente en:</p>
                            <p className="fw-bold">PRIMER LUGAR:</p>
                            <p>$500,000 M.N. (Quinientos mil pesos 00/100 Moneda Nacional)</p>
                            <h1 className="text-center mb-5 mt-5">Reglas Comunes</h1>
                            <p>1.- La Radiodifusora hará entrega al Supervisor o Supervisora de la Secretaría de
                                Gobernación de un CD que contendrá todos los datos de las personas que participaron en
                                el Concurso.</p>
                            <p>2.- Los premios se les entregarán a las personas ganadoras en la Ciudad de México, a
                                más tardar el día 13 de diciembre de 2024 en W Radio, ubicado en Calz. de Tlalpan 3000,
                                Coapa, Ex-Hacienda Coapa, Coyoacán, 04980 Ciudad de México, CDMX</p>
                            <p>3.- Para que las personas ganadoras puedan reclamar su premio es indispensable
                                presentar identificación oficial vigente y comprobante de domicilio.</p>
                            <p>4.- En caso de que alguno de los premios no sea reclamado por la persona ganadora, en
                                un plazo de 90 (noventa) días siguientes a la selección de la persona ganadora, dicho
                                premio podrá ser donado a la beneficencia pública o privada de la elección de la
                                Radiodifusora o ser utilizado para futuras promociones.</p>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Terminos;