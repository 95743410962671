import Casos from "./Casos";
import Consejos from "./Consejos";
import Ediciones from "./Ediciones";
import Header from "./Header";
import Home from "./Home";
import Jurado from "./Jurado";
import Premios from "./Premios";
import Proximamente from './Proximamente';

const Main = () =>{
    return(
        <>
       <Home/>
       <Premios/>
       <Jurado/>
       <Casos/>
       <Consejos/>
        </>
    )
}
export default Main;